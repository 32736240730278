@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --chart-6: 209 100% 56%;
    --chart-7: 346 78% 67%;
    --chart-8: 154 48% 44%;
    --chart-9: 262 60% 64%;
    --chart-10: 334 82% 44%;
    --chart-11: 45 94% 51%;
    --chart-12: 220 40% 39%;
    --chart-13: 175 67% 48%;
    --chart-14: 255 45% 53%;
    --chart-15: 240 30% 50%;
    --chart-16: 29 90% 55%;
    --chart-17: 80 45% 42%;
    --chart-18: 200 68% 75%;
    --chart-19: 10 85% 69%;
    --chart-20: 48 54% 70%;
    --chart-21: 200 83% 64%;
    --chart-22: 167 62% 47%;
    --chart-23: 356 76% 60%;
    --chart-24: 36 100% 50%;
    --chart-25: 217 55% 46%;
    --chart-26: 30 82% 62%;
    --chart-27: 125 43% 42%;
    --chart-28: 260 62% 53%;
    --chart-29: 210 76% 72%;
    --chart-30: 165 55% 35%;
    --chart-31: 350 80% 70%;
    --chart-32: 42 68% 55%;
    --chart-33: 90 41% 50%;
    --chart-34: 145 34% 45%;
    --chart-35: 295 53% 64%;
    --chart-36: 180 70% 75%;
    --chart-37: 25 85% 62%;
    --chart-38: 60 90% 40%;
    --chart-39: 50 70% 55%;
    --chart-40: 310 65% 60%;
    --chart-41: 214 100% 68%;
    --chart-42: 360 80% 65%;

    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: #d0d5dd;
    --input-active: #d6bbfb;
    --input-error: #fda29b;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: #eaecf0;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 216 34% 17%;
    --input: #d0d5dd;
    --input-error: #fda29b;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

.cell:has(.rdp-day_range_end) {
  @apply rounded-r-full;
}

.cell:has(.rdp-day_range_start) {
  @apply rounded-l-full;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.zoom-in-out-box {
  animation: zoom-in-zoom-out 1.2s 1;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.3, 1.3);
  }
  60% {
    transform: scale(0.75, 0.75);
  }
  80% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.animation-green-pulse {
  animation: pulse-green 1.5s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(9, 146, 80, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
  }

  100% {
    transform: scale(0.85);
  }
}

.animation-green-shadow-pulse {
  animation: green-shadow-pulse 1.5s infinite;
}

@keyframes green-shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(9, 146, 80, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(229, 62, 62, 0);
  }

  100% {
  }
}

.phone-ring-animation {
  animation: oscillate-rotation 1.5s infinite;
}

@keyframes oscillate-rotation {
  0% {
    transform: rotate(0) scale(1) skew(0deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(0deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(0deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(0deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(0deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(0deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(0deg);
  }
}

.fade-in-out {
  animation: fadeInOut 2s infinite;
}

html,
body {
  font-family: 'Inter', sans-serif;
}

dl {
  @apply grid grid-cols-12;
}

dt {
  @apply text-gray-400 text-sm col-span-4 self-center;
}

dd {
  @apply text-gray-700 text-sm font-semibold col-span-8 break-words whitespace-pre-wrap;
}

dl + hr {
  @apply mt-6;
}

hr {
  @apply bg-gray-200 mb-6;
}

.input-shadow {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}

.input {
  @apply !flex h-10 w-full !rounded-[8px] border focus-visible:border-input-active bg-transparent px-3 py-2 text-sm !ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground shadow-sm focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-ring disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500;
}

.pin-input {
  @apply border-input rounded-[8px] border input-shadow h-[49px] w-[49px] text-3xl font-semibold text-center focus:border-2 focus:ring-offset-1 focus:border-gray-400 outline-none disabled:border-2 disabled:border-gray-400 disabled:opacity-20;
}

.form-group-last:not(:focus) {
  @apply rounded-l-none;
}

.form-group-last:not(:focus):not(.error) {
  clip-path: inset(-5px -5px -5px 0px);
}

.form-group-last:not(.border-input-error) {
  @apply border-l-0;
}

.form-group-last:focus {
  @apply rounded-l-none;
}

.form-group-first {
  @apply border-r-0 rounded-r-none;
}

.layout {
  @apply grid;
  grid-template-columns: auto 1fr;
}

.account-grid-item {
  grid-template-columns: repeat(3, minmax(290px, 354px));
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
/* Hide Google Maps UI */
.gmnoprint,
.gm-style-cc,
.address-book .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none !important;
}

.contact-page .gmnoprint,
.contact-page .gm-style-cc,
.address-book .gmnoprint {
  display: block !important;
}
.address-book .gmnoprint.gm-style-mtc-bbw button {
  font-size: 11px !important;
  padding: 8px !important;
  height: 29px !important;
}
.address-book .gmnoprint.gm-style-mtc-bbw ul li {
  font-size: 11px !important;
}
.address-book .gmnoprint.gm-bundled-control {
  right: 30px !important;
}
.address-book .gmnoprint.gm-bundled-control img {
  width: 10px !important;
  height: 10px !important;
}
.address-book .gmnoprint.gm-bundled-control button {
  width: 29px !important;
  height: 29px !important;
}
.address-book .gmnoprint.gm-bundled-control > div > div {
  width: 29px !important;
  height: 56px !important;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

/* Time  picker */
.react-datepicker__triangle {
  display: none !important ;
}
.react-datepicker__time-container {
  width: 180px !important;
}

.react-datepicker-popper {
  z-index: 21 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}
.react-datepicker__header {
  border-color: #d0d5dd !important;
  background-color: #f2f4f7 !important;
}
.react-datepicker-time__header {
  color: #101323 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #eaecf0 !important;
  color: #101323 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgb(243 192 76 / var(--tw-bg-opacity)) !important;
  color: white !important;
}

.cl-organizationSwitcherPopoverActionButton__createOrganization {
  @apply hidden;
}

.rdp-tbody td {
  @apply w-full;
}

.navigation-clip {
  clip-path: polygon(54% 48%, 100% 100%, 100% 0);
}

.navigation-clip-collapsed {
  clip-path: polygon(20% 50%, 100% 100%, 100% 0);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.truncate:before {
  content: '';
  display: block;
}

.cl-organizationSwitcherTrigger .cl-organizationPreview + div {
  @apply hidden;
}
